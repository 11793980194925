import React from "react";
import { Container } from "reactstrap";
import styled from "styled-components";

const Bar = styled.div`
  padding: 25px 0;
  background: ${props => props.theme.darkGrey || `#333`};
  color: white;
  border-bottom: 1px solid lightgray;
  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    list-style: none;
    margin: 0;

    li::before {
      content: "✓";
      color: ${props => props.theme.orange || `orange`};
      position: absolute;
      margin-left: -20px;
    }
  }
  @media (max-width: 991px) {
    display: none;
  }
`;

const TopBar = () => {
  return (
    <Bar>
      <Container>
        <ul>
          <li>Call 01823 666025 for a free quote</li>
          <li>Available 24/7</li>
          <li>Fully insured</li>
          <li>Latest products and equipment</li>
        </ul>
      </Container>
    </Bar>
  );
};

export default TopBar;
