import React from "react";
import { Link } from "gatsby";
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import TopBar from "./topbar";
import styled from "styled-components";
import Logo from "assets/images/logo.png";

const StyledNavbar = styled(Navbar)`
  background: ${props => props.theme.darkGrey || `#333`};
  color: white;
  padding: 25px;
`;

export default class Example extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <>
        <TopBar />
        <StyledNavbar color="blue" dark expand="md">
          <Container>
            <NavbarBrand tag={Link} to="/">
              <img width="250" src={Logo} alt="Cleanwright Logo" />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink activeClassName="active" tag={Link} to="/">
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink activeClassName="active" tag={Link} to="/services">
                    Services
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink activeClassName="active" tag={Link} to="/faqs">
                    FAQs
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    activeClassName="active"
                    tag={Link}
                    to="/previous-job-examples"
                  >
                    Examples
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink activeClassName="active" tag={Link} to="/quote">
                    Get a quote
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </StyledNavbar>
      </>
    );
  }
}
