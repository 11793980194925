import React from "react";
import { Container } from "reactstrap";
import styled from "styled-components";

const StyledFooter = styled.footer`
  background: ${props => props.theme.darkGrey || `#333`};
  color: white;
  padding: 120px 0;
  height: auto;
  text-align: center;
  hr {
    width: 25%;
    border-top: 1px solid lightgray;
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <h3>
          Call Steve today on <span className="orange">01823 666025</span> for
          your free quote.
        </h3>
        <hr />
        <p>© CleanWright {new Date().getFullYear()}</p>
        <hr />
        <p className="serif">
          Another cracking website built by{" "}
          <a href="https://kozocreative.com">Kozo Creative</a>
        </p>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
