import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { ThemeProvider } from "styled-components";
import "assets/stylesheets/application.scss";

import Header from "components/Header";
import Footer from "components/Footer";

const theme = {
  darkGrey: `#333`,
  lightGrey: `#6B6B6B`,
  orange: `#FF9933`
};

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Helmet>
          <title>CleanWright</title>
        </Helmet>
        <Header />
        <main>{children}</main>
        <Footer />
      </>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
