import styled from "styled-components";
import Hero from "assets/images/carpet-cleaning.jpg";

const Section = styled.section`
  padding: 120px 0;
  color: #333;
`;

const DarkGreySection = styled(Section)`
  background: ${props => props.theme.darkGrey};
  color: white;
`;

const HomeSection = styled(Section)`
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)),
    url(${Hero});
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .container {
    display: flex;
    flex-wrap: wrap;
    .home-left {
      flex: 1 1 500px;
      h1 {
        text-align: left;
      }
    }
    .home-right {
      flex: 1 1 300px;
      padding: 0 20px;
    }
  }
  color: white;
`;

const PageHeroSection = styled(Section)`
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)),
    url(${props => (props.image ? props.image : Hero)});
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  color: white;
`;

const LightGreySection = styled(Section)`
  background: ${props => props.theme.lightGrey};
  color: white;
`;

export {
  Section,
  PageHeroSection,
  HomeSection,
  LightGreySection,
  DarkGreySection
};
